.modal-send-gift {
  max-width: 1000px;
  .modal-title {
    display: flex;
    align-items: center;
    font-size: 1.5rem;
    font-weight: 600;
  }
  .modal-body {
    background-color: var(--gf-dark-violet-50);
    padding-left: 0;
    padding-right: 0;
    border-radius: 12px;
    .card-body {
      overflow: hidden;
      .title-contain {
        min-height: 80px;
      }
      .card-title {
        text-align: left;
        min-height: 2.4rem;
      }
      .card-subtitle {
        text-align: left;
        margin: 20px 0;
        span {
          padding: 5px 10px;
          border: 1px solid var(--gf-neutral-200);
          border-radius: 25px;
          color: #000;
        }
      }
      .contain {
        min-height: 275px;
      }
      .labeling {
        background-color: var(--gf-success-50);
        color: #000;
        padding: 0; // Remove padding
        margin-left: calc(-100vw / 2 + 500px / 2);
        margin-right: calc(-100vw / 2 + 500px / 2);
        position: relative;
        margin-top: 20px;
        margin-bottom: 20px;
        &:before {
          content: '';
          height: 2px;
          width: 100%;
          background-color: var(--gf-neutral-200);
          position: absolute;
          top: -10px;
          left: 0;
          right: 0;
        }
        &:after {
          content: '';
          height: 2px;
          width: 100%;
          background-color: var(--gf-neutral-200);
          position: absolute;
          bottom: -10px;
          left: 0;
          right: 0;
        }
      }
    }
  }

  .send-gift-options {
    padding: 1rem 0;

    .send-gift-option {
      cursor: pointer;
      transition: all 0.3s ease;

      &:hover {
        transform: translateY(-2px);
      }

      .option-icon {
        color: #ffc700;
      }

      h4 {
        font-size: 1rem;
        font-weight: 600;
        margin-bottom: 0.25rem;
      }

      p {
        font-size: 0.85rem;
        margin-bottom: 0;
      }
    }
  }

  .footer {
    margin-top: 1rem;
    padding-top: 1rem;
    border-top: 1px solid #eee;
  }
}

.icon-size {
  font-size: 14px; // Set a consistent size for all icons
}
